import React, { useState, useEffect } from 'react';

const ROWS = 20;
const COLS = 30;

const Game = () => {
  const initialize = () => {
    const grid = [];
    for (let i = 0; i < ROWS; i += 1) {
      grid.push(Array.from(Array(COLS), () => 0));
    }
    return grid;
  };

  const randomize = (currentGrid) => {
    const newGrid = currentGrid.slice();
    for (let i = 1; i < currentGrid.length - 1; i += 1) {
      for (let j = 1; j < currentGrid[i].length - 1; j += 1) {
        newGrid[i][j] = Math.floor(Math.random() * 1.4);
      }
    }
    return newGrid;
  };

  const nextGeneration = (currentGrid) => {
    const newGrid = currentGrid.slice();
    for (let i = 1; i < currentGrid.length - 1; i += 1) {
      for (let j = 1; j < currentGrid[i].length - 1; j += 1) {
        let liveNeighbours = 0;
        liveNeighbours += currentGrid[i - 1][j + 1];
        liveNeighbours += currentGrid[i - 1][j];
        liveNeighbours += currentGrid[i - 1][j - 1];
        liveNeighbours += currentGrid[i + 1][j];
        liveNeighbours += currentGrid[i + 1][j + 1];
        liveNeighbours += currentGrid[i + 1][j - 1];
        liveNeighbours += currentGrid[i][j + 1];
        liveNeighbours += currentGrid[i][j - 1];

        if (currentGrid[i][j] === 1) {
          newGrid[i][j] = liveNeighbours === 2 || liveNeighbours === 3 ? 1 : 0;
        } else {
          newGrid[i][j] = liveNeighbours === 3 ? 1 : 0;
        }
      }
    }
    return newGrid;
  };

  const [grid, setGrid] = useState(() => initialize());

  useEffect(() => {
    setGrid(randomize(grid));
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => setGrid(nextGeneration(grid)), 600);
    return () => clearInterval(timeout);
  }, [grid]);

  const cellColour = (cell) => {
    if (cell === 0) { return undefined; }
    const hue = Math.floor(Math.random() * 360);
    const saturation = 20 + Math.floor(Math.random() * 60);
    const lightness = 20 + Math.floor(Math.random() * 60);
    return `hsl(${hue},${saturation}%,${lightness}%)`;
  };

  return (
    <div className="container">
      <div
        className="grid"
        style={{
          gridTemplateColumns: `repeat(${COLS}, 20px)`,
        }}
      >
        {grid.map((rows, i) => rows.map((col, j) => (
          <div
            className="cell"
            // eslint-disable-next-line react/no-array-index-key
            key={`${i}-${j}`}
            style={{
              backgroundColor: cellColour(grid[i][j]),
            }}
          />
        )))}
      </div>
    </div>
  );
};

export default Game;
